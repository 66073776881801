import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import {
  ThgBillingItemDocumentGen,
  ThgBillingPdfDataGen,
  ThgBillingPdfInputGen,
  ThgContactDocumentGen,
  ThgGeneralTemplateCompanyInfoGen,
  ThgPdfMetaDataGen,
  ThgProductGen
} from "@/services/thg/v1/data-contracts";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { Address, IAddress } from "./address.entity";
import { Contact } from "./contact.entity";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { PdfTypeEnum } from "@/lib/enum/pdf-type.enum";

@IsFilterable
class ThgProductBase implements ThgProductGen {
  pricePerProduct: number;
  isDeductible: boolean;
  type?:
    | "payoutAffiliateFlexPartner"
    | "payoutAffiliateFlexFriend"
    | "payoutAffiliatePartner"
    | "payoutAffiliateFriend"
    | "payoutAffiliateFixedFriendAt"
    | "payoutAffiliateFlexFriendAt"
    | "payoutAffiliateFixedPartnerAt"
    | "payoutAffiliateFlexPartnerAt"
    | "payoutFixedCompany"
    | "payoutFlexCompany"
    | "payoutFixedPrivate"
    | "payoutFlexPrivate"
    | "payoutFixedCompanyAt"
    | "payoutFlexCompanyAt"
    | "payoutFixedPrivateAt"
    | "payoutFlexPrivateAt"
    | "payoutMeterReadingCompany"
    | "payoutMeterReadingCompanyAt"
    | "payoutMeterReadingPrivate"
    | "payoutMeterReadingPrivateAt"
    | "payoutPartnerCommissionCompany"
    | "payoutPartnerCommissionCompanyAt"
    | "payoutPartnerCommissionPrivate"
    | "payoutPartnerCommissionPrivateAt"
    | "impactCharging"
    | "impactRenewable"
    | "impactPayout"
    | "impactTrees"
    | "promotionFirstPurchasePrivate"
    | "promotionFirstPurchasePrivateAt"
    | "promotionFirstPurchaseCompany"
    | "promotionFirstPurchaseCompanyAt";
  name: string;
  description?: string | undefined;
  accountingSuffix?: string | undefined;

  constructor(product: ThgProductGen) {
    this.pricePerProduct = product?.pricePerProduct;
    this.isDeductible = product?.isDeductible;
    this.type = product?.type;
    this.name = product?.name;
    this.description = product?.description;
    this.accountingSuffix = product?.accountingSuffix;
  }
}

type IThgProduct = ThgProductBase;
const ThgProduct = Filter.createForClass(ThgProductBase);

export { IThgProduct, ThgProduct };

@IsFilterable
class ThgBillingItemDocumentBase implements ThgBillingItemDocumentGen {
  @FilterConfig({
    type: ThgProduct
  })
  product: ThgProductGen;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  amount: number;

  @FilterConfig({
    type: FilterTypes.BOOLEAN
  })
  isVat: boolean;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  totalPriceWithoutTax: number;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  vat: number;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  totalPriceWithVat: number;

  constructor(billingItemDocument: ThgBillingItemDocumentGen) {
    this.product = billingItemDocument?.product;
    this.amount = billingItemDocument?.amount;
    this.isVat = billingItemDocument?.isVat;
    this.totalPriceWithoutTax = billingItemDocument?.totalPriceWithoutTax;
    this.vat = billingItemDocument?.vat;
    this.totalPriceWithVat = billingItemDocument?.totalPriceWithVat;
  }
}

type IThgBillingItemDocument = ThgBillingItemDocumentBase;
const ThgBillingItemDocument = Filter.createForClass(ThgBillingItemDocumentBase);

export { IThgBillingItemDocument, ThgBillingItemDocument };

@IsFilterable
class ThgGeneralTemplateCompanyInfoBase implements ThgGeneralTemplateCompanyInfoGen {
  @FilterConfig({
    type: FilterTypes.STRING
  })
  name: string;

  @FilterConfig({
    type: Address
  })
  address: IAddress;

  @FilterConfig({
    type: Contact
  })
  contact: ThgContactDocumentGen;

  constructor(companyInfo: ThgGeneralTemplateCompanyInfoGen) {
    this.name = companyInfo?.name;
    this.address = companyInfo?.address;
    this.contact = companyInfo?.contact;
  }
}

type IThgGeneralTemplateCompanyInfo = ThgGeneralTemplateCompanyInfoBase;
const ThgGeneralTemplateCompanyInfo = Filter.createForClass(ThgGeneralTemplateCompanyInfoBase);

export { IThgGeneralTemplateCompanyInfo, ThgGeneralTemplateCompanyInfo };

@IsFilterable
class ThgBillingPdfInputBase implements ThgBillingPdfInputGen {
  @FilterConfig({
    type: ThgBillingItemDocument
  })
  billingItems: ThgBillingItemDocumentGen[];

  @FilterConfig({
    type: FilterTypes.STRING
  })
  name: string;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  nameLine2?: string | undefined;

  @FilterConfig({
    type: Address
  })
  address: IAddress;

  @FilterConfig({
    type: ThgGeneralTemplateCompanyInfo
  })
  companyData: ThgGeneralTemplateCompanyInfoGen;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(BillingTypeEnum).map(v => {
        return {
          text: `enums.BillingTypeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  billingType: BillingTypeEnum;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  billingNumber: number;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  cancellationNumber?: number | undefined;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  userName?: string | undefined;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  accountNumber: number;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  taxnumber?: string | undefined;

  @FilterConfig({
    type: FilterTypes.DATE
  })
  serviceDateFrom?: string | undefined;

  @FilterConfig({
    type: FilterTypes.DATE
  })
  serviceDateTo?: string | undefined;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  iban: string;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  bankName: string;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  companyName?: string | undefined;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  appUrl?: string | undefined;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  bottomText?: string | undefined;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.billing.isTaxDeductible"
  })
  isTaxDeductible?: boolean;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.billing.countryCode",
    config: {
      items: Object.values([CountryCodeEnum.germany, CountryCodeEnum.austria]).map(v => {
        return {
          text: `enums.BillingTypeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode?: CountryCodeEnum;

  constructor(billingPdfInput: ThgBillingPdfInputGen) {
    this.billingItems = billingPdfInput?.billingItems;
    this.name = billingPdfInput?.name;
    this.nameLine2 = billingPdfInput?.nameLine2;
    this.address = billingPdfInput?.address;
    this.companyData = billingPdfInput?.companyData;
    this.billingType = billingPdfInput?.billingType as BillingTypeEnum;
    this.billingNumber = billingPdfInput?.billingNumber;
    this.cancellationNumber = billingPdfInput?.cancellationNumber;
    this.userName = billingPdfInput?.userName;
    this.accountNumber = billingPdfInput?.accountNumber;
    this.taxnumber = billingPdfInput?.taxnumber;
    this.serviceDateFrom = billingPdfInput?.serviceDateFrom;
    this.serviceDateTo = billingPdfInput?.serviceDateTo;
    this.iban = billingPdfInput?.iban;
    this.bankName = billingPdfInput?.bankName;
    this.companyName = billingPdfInput?.companyName;
    this.appUrl = billingPdfInput?.appUrl;
    this.bottomText = billingPdfInput?.bottomText;
    this.isTaxDeductible = billingPdfInput?.isTaxDeductible;
    this.countryCode = billingPdfInput?.countryCode as CountryCodeEnum;
  }
}

type IThgBillingPdfInput = ThgBillingPdfInputBase;
const ThgBillingPdfInput = Filter.createForClass(ThgBillingPdfInputBase);

export { IThgBillingPdfInput, ThgBillingPdfInput };

@IsFilterable
class ThgPdfMetaDataBase implements ThgPdfMetaDataGen {
  @FilterConfig({
    type: FilterTypes.STRING
  })
  originalName: string;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  size: number;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  firstPageWidth: number;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  firstPageHeight: number;

  @FilterConfig({
    type: FilterTypes.NUMBER
  })
  pageCount: number;

  constructor(pdfMetaData: ThgPdfMetaDataGen) {
    this.originalName = pdfMetaData?.originalName;
    this.size = pdfMetaData?.size;
    this.firstPageWidth = pdfMetaData?.firstPageWidth;
    this.firstPageHeight = pdfMetaData?.firstPageHeight;
    this.pageCount = pdfMetaData?.pageCount;
  }
}

type IThgPdfMetaData = ThgPdfMetaDataBase;
const ThgPdfMetaData = Filter.createForClass(ThgPdfMetaDataBase);

export { IThgPdfMetaData, ThgPdfMetaDataBase as ThgPdfMetaData };

@IsFilterable
class ThgBillingPdfDataBase implements ThgBillingPdfDataGen {
  @FilterConfig({
    type: FilterTypes.STRING
  })
  folderName: string;

  @FilterConfig({
    type: ThgPdfMetaData
  })
  metaData: IThgPdfMetaData;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  name: string;

  @FilterConfig({
    type: FilterTypes.STRING
  })
  url: string;

  @FilterConfig({
    displayName: "objects.document.type",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(PdfTypeEnum).map(v => {
        return {
          text: `enums.PdfTypeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  type: PdfTypeEnum;

  @FilterConfig({
    type: ThgBillingPdfInput
  })
  input: ThgBillingPdfInputGen;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  constructor(billingPdfData: ThgBillingPdfDataGen) {
    this.folderName = billingPdfData?.folderName;
    this.metaData = billingPdfData?.metaData;
    this.name = billingPdfData?.name;
    this.url = billingPdfData?.url;
    this.type = billingPdfData?.type as PdfTypeEnum;
    this.timestamp = new Timestamp(billingPdfData.timestamp);
    this.input = billingPdfData?.input;
  }
}

type IThgBillingPdfData = ThgBillingPdfDataBase;
const ThgBillingPdfData = Filter.createForClass(ThgBillingPdfDataBase);

export { IThgBillingPdfData, ThgBillingPdfData };
