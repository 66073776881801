import { DebitOrCreditEnum } from "@/lib/enum/debit-or-credit.enum";
import { FilterTypes, IsFilterable, FilterConfig } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { ThgAccountingRecordItemGen } from "@/services/thg/v1/data-contracts";

@IsFilterable
class AccountingRecordItemBase implements ThgAccountingRecordItemGen {
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "Amount"
  })
  amount: number;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(DebitOrCreditEnum).map(v => {
        return {
          text: `enums.DebitOrCreditEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "Billing Type"
  })
  debitOrCredit: DebitOrCreditEnum;

  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "account"
  })
  account: number;

  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "contraAccount"
  })
  contraAccount: number;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "bookingText"
  })
  bookingText: string;

  constructor(accountingRecordItem: ThgAccountingRecordItemGen) {
    this.amount = accountingRecordItem?.amount;
    this.debitOrCredit = accountingRecordItem?.debitOrCredit as DebitOrCreditEnum;
    this.account = accountingRecordItem?.account;
    this.contraAccount = accountingRecordItem?.contraAccount;
    this.bookingText = accountingRecordItem?.bookingText;
  }
}

// not configured well in backend. Make searchable in backend to enable this
// export const AccountingRecordItem = Filter.createForClass(AccountingRecordItemBase);
export const AccountingRecordItem = AccountingRecordItemBase;
